import { Loader } from '@googlemaps/js-api-loader';

export const useGoogleMapLoaderInstance = () =>
  <Ref<Loader | null>>useState('gMapLoader', () => null);

export const useGoogleMapLoader = async (): Promise<Loader> => {
  const loader = useGoogleMapLoaderInstance();
  if (loader.value) return loader.value;

  const config = useAppConfig();
  loader.value = new Loader({
    apiKey: config.firebase.apiKey,
    version: 'weekly',
  });
  await loader.value.importLibrary('core');
  return loader.value;
};
